@import "styles/constants.scss";

.approve-token-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $field-blue;
  box-shadow: 0px 4px 4px $black-shadow;
  position: relative;
  color: $white;
  max-width: 400px;
  text-align: center;

  @media only screen and (max-width: $mobile-breakpoint) {
    height: 100%;
    justify-content: center;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: $moderate;
    &:hover {
      opacity: 0.9;
    }
  }

  .title {
    font-size: $moderate;
    font-weight: bold;
    margin: 40px 0;
  }

  .unlimited-approval-button {
    width: 100%;
    background-color: $turquoise;
    color: $field-blue;
    font-weight: bold;
    margin: 25px 0;
  }

  .sub-text {
    font-size: $xxsmall;
    text-align: center;
    margin: 15px 0;

    &.yellow {
      color: $yellow;
    }
  }

  .limited-approval-button {
    background: transparent;
    color: $white;
    text-decoration: underline;
    border: none;
  }
}
