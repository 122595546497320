@import "styles/constants.scss";

.notifications-container {
  position: fixed;
  z-index: $notifications-container-z-index;
  right: 50%;
  transform: translateX(50%);
  top: 0;
  width: 100%;
}
