@import "./constants.scss";

.nft-prize-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  
  .nft-name {
    margin-bottom: 30px;
    color: $white;
    font-weight: bold;
    margin-right: auto;
  }
  .nft-info {
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    color: $white;
    .subtitle {
      font-style: italic;
      color: $dark-turquoise;
      margin-bottom: 5px;
    }
    .data {
      font-size: $xsmall;
      margin-bottom: 20px;
    }
  }
  .nft-prize__video {
    max-height: 350px;
  }
}
