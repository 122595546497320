@import "styles/constants.scss";

.dot-wrapper {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  // margin: 0 10px;
  margin-top: 2px;
}
