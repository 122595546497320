@import "../constants.scss";

.loading-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  transform: translate(50%, -50%);
  z-index: 5000;
  
  &.fixed {
    position: fixed;
    background-color: $transparent-black;
    width: 100%;

    height: 100%;
    justify-content: center;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }

  .spinner {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../assets/icons/loading.svg");
    width: 40px;
    height: 40px;
  }

  .hat-loader {
    animation: hatChange 1s infinite;
    background-position: center;
    background-size: contain;
    background-image: url("../../assets/icons/logo-left.svg");
    width: 100px;
    height: 100px;
  }

  .extra-text {
    color: $white;
    padding: 0 20px;
    text-align: center;
  }
}

@keyframes hatChange {
  0%,
  100% {
    background-image: url("../../assets/icons/logo-left.svg");
  }
  50% {
    background-image: url("../../assets/icons/logo-right.svg");
  }
}
