@import "styles/constants.scss";

.token-select-wrapper {
  .select-tokens {
    .token-option-wrapper {
      display: flex;
      align-items: center;
      color: $white;
      .token-icon {
        border-radius: 100px;
        width: 30px;
        height: 30px;
        margin-right: 15px;
      }
    }
  }
  .token-icon-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    .icons-wrapper {
      position: relative;
    }

    .token-icon {
      border-radius: 100px;
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }
  }
}
