@import "styles/constants.scss";

.notification-wrapper {
  background-color: $dark-turquoise;
  padding: 0.5rem;
  margin-bottom: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  color: $blue-3;
  .notification__content {
    margin: auto;
  }
  .dismiss-btn {
    background: none;
    border: none;
    color: $white;
    font-weight: bold;
    margin-left: auto;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
