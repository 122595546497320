@import "styles/constants.scss";
@import "rc-tooltip/assets/bootstrap_white.css";

.deposit-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $field-blue;
  width: 600px;

  @media only screen and (max-width: $small-mobile-breakpoint) {
    width: 100%;
  }

  .pool-wrapper {
    display: flex;
    align-items: center;
    background-color: $blue;
    font-size: $moderate;
    font-weight: bold;
    padding: 20px;
    justify-content: space-between;
    .pool-title-wrapper {
      display: flex;
      align-items: center;
      .pool-name {
        margin-left: 20px;
      }
    }
  }
  > div,
  > table {
    padding: 0px $modal-element-padding;
  }

  .tabs-wrapper {
    display: flex;
    padding: 0;
    .tab {
      height: 65px;
      width: 100%;
      color: $white;
      font-size: $moderate;
      background: transparent;
      border: none;
      &.selected {
        border-bottom: 2px solid $turquoise;
        font-weight: bold;
      }
    }
  }

  .content {
    position: relative;
  }

  .info-banner {
    padding: 0;

    .extra-info-wrapper {
      color: $dark-blue;
      margin-top: 20px;
      background-color: $yellow;
      text-align: center;
      line-height: 32px;
      font-size: $xxsmall;
    }
  }

  .deposit-tokens-wrapper {
    display: flex;
    margin-top: 10px;
    .deposit-token {
      display: flex;
      align-items: center;
      opacity: 0.3;
      &.redeemed {        
        opacity: 1;
      }
      &.eligible {
        opacity: .3;
        border: dashed 1px $turquoise;
      }
      img {
        width: 30px;
        height: 30px;
      }
      margin-right: 10px;
    }
  }

  .balance-wrapper {
    position: relative;
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    color: $white;
    display: flex;
    align-items: center;

    &.disabled {
      opacity: 0.3;
    }

    .max-button {
      color: $turquoise;
      background: none;
      height: fit-content;
      padding: 0;
      border: none;
    }

  }

  .amount-wrapper {
    display: flex;
    flex-direction: column;
    padding: 25px 30px;
    background-color: $dark-blue;
    border: 1px solid $field-blue;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .input-wrapper {
      display: flex;
      justify-content: space-between;
      font-size: $moderate;
      color: $white;

      .pool-token {
        display: flex;
        align-items: center;
        width: 100%;
      }

      span {
        margin-left: 10px;
        white-space: nowrap;
      }

      input {
        background-color: transparent;
        border: none;
        color: inherit;
        text-align: right;
        font-weight: bold;
        font-size: inherit;
        width: 100%;
      }
    }

    .input-error {
      align-self: flex-end;
      color: $red;
      font-size: $xsmall;
      margin-top: 10px;
    }
  }
  .percentage-btn {
    background-color: $black;
    border: 1px solid $field-blue;
    color: $white;
    width: 70px;
    &:first-child {
      margin-left: 0px;
    }
  }

  .deposited-wrapper {
    display: flex;
    font-size: $moderate;
    color: $white;
    margin: 20px 0;
    flex-direction: column;
    > div {
      display: flex;
      justify-content: space-between;
      margin: 5px 0px;
    }
  }
  .apy-wrapper {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $field-blue;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    color: $white;
    padding-bottom: 25px;
    border-bottom: 1px solid $turquoise;
    margin-bottom: 25px;
    span:nth-child(1) {
      display: flex;
      align-items: center;
    }
  }
  .withdraw-timer-wrapper {
    display: flex;
    justify-content: space-between;
    color: $white;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .terms-of-use-wrapper {
    color: $white;
    margin: 20px 0;
    display: flex;
    align-items: center;
    input {
      width: 20px;
      height: 20px;
    }
    label {
      margin-left: 10px;
    }
    &.disabled {
      opacity: 0.4;
    }
  }
  .action-btn-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 40px;
    align-items: center;
    .action-btn {
      width: 80%;
      margin: 0;
      position: relative;
      &.claim-btn {
        margin-top: 20px;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    animation: disabledFade 0.4s;
  }

  .extra-status-info {
    width: 90%;
    text-align: center;
    font-size: $xxsmall;
    margin-top: 15px;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .deposit-wrapper {
    > div {
      padding: 0px $modal-element-padding-mobile;
    }
    .action-btn-wrapper {
      .action-btn {
        width: 100%;
      }
    }
  }
}
