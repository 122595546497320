@import "styles/constants.scss";

.airdrop-redeem-success-wrapper {
  display: flex;
  flex-direction: column;
  color: $white;
  max-width: 450px;
  white-space: pre-line;

  .airdrop-redeem-success__icon {
    width: 100px;
    align-self: center;
  }
  .airdrop-redeem-success__title {
    font-weight: bold;
    font-size: $medium;
    text-align: center;
    margin: 20px 0;
  }
  .airdrop-redeem-success__sub-title {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 5px;
  }
  .airdrop-redeem-success__features-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .airdrop-redeem-success__feature {
      display: flex;
      align-items: center;
      margin: 15px 0;
      img {
        align-self: baseline;
        margin-right: 10px;
      }
    }
  }
  .airdrop-redeem-success__nfts-wrapper {
    margin: 25px 0;
    .swiper-wrapper {
      .swiper-slide {
        display: flex;
        justify-content: center;
        video {
          width: 100%;
        }
      }
    }
  }
  .airdrop-redeem-success__join-embassy-btn-container {
    display: flex;
    position: sticky;
    bottom: 0;
    background-color: $field-blue;
    z-index: 100;
    padding-top: 20px;
    .airdrop-redeem-success__join-embassy-btn {
      background-color: $strong-purple-2;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-weight: bold;
      width: 100%;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .airdrop-redeem-success-wrapper {
    padding: $modal-element-padding-mobile;
    padding-bottom: 0;
    max-width: unset;
    .airdrop-redeem-success__projects {
      text-align: center;
    }
    .airdrop-redeem-success__nfts-wrapper {
      .swiper-wrapper {
        .swiper-slide {
          video {
            width: 200px;
          }
        }
      }
    }
    .airdrop-redeem-success__join-embassy-btn-container {
      margin: 0 -#{$modal-element-padding-mobile};
      padding: 0 $modal-element-padding-mobile;
      padding-top: 20px;
    }
  }
}
