@import "styles/constants.scss";

.vault-action-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  > span {
    font-size: $xxsmall;
  }

  .deposit-withdraw {
    border: 1px solid;
    color: $turquoise;
    background-color: $light-blue;
    &:active {
      color: $white;
      background-color: $dirty-turquoise;
      opacity: 1;
    }
  }

  .active-claim {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--white);
    margin-bottom: 8px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    .icon {
      color: var(--warning-yellow);
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .vault-action-wrapper {
    padding: 20px;
    .deposit-withdraw {
      width: 100%;
    }
  }
}
