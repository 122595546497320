@import "styles/constants.scss";

.embassy-nft-ticket-wrapper {
  display: flex;
  flex-direction: column;
  color: $white;
  white-space: pre-line;
  width: 500px;

  .embassy-nft-ticket__icon {
    width: 100px;
    align-self: center;
  }
  .embassy-nft-ticket__title {
    font-weight: bold;
    font-size: $medium;
    text-align: center;
    margin: 20px 0;
  }
  .embassy-nft-ticket__redeem-btn {
    margin-top: 20px;
  }
  .swiper {
    margin-left: unset;
    margin-right: unset;
    margin-top: 15px;
    margin: 25px 0;
    .swiper-wrapper {
      .swiper-slide {
        display: flex;
        justify-content: center;
        video {
          width: 100%;
        }
      }
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .embassy-nft-ticket-wrapper {
    padding: $modal-element-padding-mobile;
    padding-top: 0px;
    text-align: center;
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          video {
            width: 200px;
          }
        }
      }
    }
  }
}
