/*  */
$element-padding-mobile: 20px;
$modal-element-padding: 40px;
$modal-element-padding-mobile: 20px;
$element-max-width: 1220px;
$element-max-width-md: 900px;
$header-button-hight: 56px;
$notifications-container-z-index: 20000;
$nft-card-z-index: 9999;

// NEW COLORS
$primary: #614fda;
$primary-light: #4f41a7;
$secondary: #24e8c5;
$background: #0e0e18;
$background-1: #171936;
$background-2: #24285f;

/* colors */
$white: #ffffff;
$black: #000206;
$dark-blue: #000723;
$moderate-blue: #000930;
$strong-blue: #001571;
$blue: #000c3b;
$blue-2: #101b4e;
$blue-3: #1c2c74;
$blue-4: #6472af;
$field-blue: #0c1436;
$light-blue: #041558;
$light-blue-2: #4c80d0;
$purple-blue: #1f2748;
$vault-blue: #0524a7;
$turquoise: #8afcfd;
$turquoise-1: #58ccb1;
$turquoise-2: #428a8d;
$turquoise-3: #24a19b;
$dark-turquoise: #9bc8ca;
$dirty-turquoise: #64c1c5;
$red: #eb889d;
$red-2: #f24822;
$strong-red: #e66480;
$green: green;
$bright-green: #05ff00;
$drak-green: #34d058;
$orange: #d1891d;
$yellow: #f2c94c;
$transparent-black: rgba(0, 0, 0, 0.2); // for modal
$gray: #c6c6c6;
$gray-2: #8d8d8d;
$light-gray: #212842;
$azure: #13a8fd;
$purple: #4f72eb;
$strong-purple: #4b239f;
$strong-purple-2: #5662f6;

/* pie chart colors */
$token: #14595b;
$vested-token: #296b6d;
$committee: #3e7d7f;
$vested-hats: #548e91;
$governance: #69a0a3;
$swap-and-burn: #7eb2b5;

$severity-audit-request: #d1891d;

$committee-blue: #c0f2f2;

$vault-editor-border: #59648f;
$vault-editor-border-2: #212842;

/* font sizes */
$xlarge: 48px;
$large: 36px;
$medium-2: 32px;
$medium: 24px;
$moderate: 18px;
$small: 16px;
$xsmall: 14px;
$xxsmall: 12px;
$tiny: 10px;

/* spacing */
$spacing-quarter: 2px;
$spacing-half: 4px;
$spacing-1: 8px;
$spacing-2: 16px;
$spacing-3: 24px;
$spacing-4: 32px;
$spacing-5: 40px;
$spacing-6: 48px;
$spacing-7: 56px;
$spacing-8: 64px;
$spacing-9: 72px;
$spacing-10: 80px;
$spacing-11: 88px;
$spacing-12: 96px;
$spacing-13: 104px;
$spacing-14: 112px;
$spacing-15: 120px;
$spacing-16: 128px;
$spacing-18: 144px;
$spacing-20: 160px;
$spacing-25: 200px;

/* others */
$small-mobile-breakpoint: 600px;
$mobile-breakpoint: 1100px;
$small-screen-breakpoint: 1600px;
$black-shadow: 0 13px 32px rgb(0 0 0 / 30%); // for modal
$sidebar-shadow: rgba(0, 0, 0, 0.25);
