@import "styles/constants.scss";

.nft-card-wrapper {
  display: flex;
  flex-direction: column;
  border: 2px solid $blue-4;
  border-radius: 15px;
  position: relative;
  // width: 180px;

  .nft-card__info-container {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    border-top: 1px solid $blue-3;

    .nft-card__info-title {
      font-size: $small;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .nft-card__info-element {
      display: flex;
      flex-direction: column;
      margin: 5px 0;
      .nft-card__info-element-title {
        color: $committee-blue;
        font-size: $xxsmall;
        font-style: italic;
      }
      .nft-card__info-element-value {
        text-transform: capitalize;
        font-size: $xxsmall;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  video {
    max-height: 350px;
    padding: 14px;
  }
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
  &.not-redeemed {
    border: 1px dashed $blue-4;
  }
  .nft-card__status {
    padding: 6px 10px;
    position: absolute;
    text-align: center;
    border-bottom-right-radius: 13px;
    border-bottom-left-radius: 13px;
    left: 50%;
    transform: translateX(-50%);
    font-size: $xsmall;
    &.eligible {
      color: $white;
      background-color: $red-2;
    }
    &.redeemed {
      color: $field-blue;
      background-color: $turquoise;
    }
  }
}

.nft-card-full-screen-wrapper {
  width: 100%;
  height: 100%;
  background-color: $black;
  z-index: $nft-card-z-index;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  .nft-card-full-screen__close-btn {
    border: none;
    color: $white;
    margin-left: auto;
    font-size: $medium-2;
  }
  .nft-card-full-screen__container {
    margin: auto;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    flex-direction: column;
    .nft-card-full-screen__video {
      height: 80vh;
    }
    .nft-card-full-screen__opensea-link {
      display: flex;
      align-items: center;
      margin-top: 10px;
      img {
        margin-left: 5px;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .nft-card-wrapper {
    .nft-card__info-container {
      padding: 10px;
      height: 100px;
      .nft-card__info-title {
        font-size: $xsmall;
      }
    }
    .nft-card__status {
      height: 30px;
      line-height: 30px;
      font-size: $xxsmall;
    }
  }
}
